.spin-octicon {
  animation: spin-octicon 2s infinite linear;
}
@keyframes spin-octicon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
